export default {
  SETUP: "/setup",
  LEAGUE: "/setup/league",
  LEAGUE_TEAM: "/setup/league-team",
  ALLUP: "/setup/all-up",
  TEAM: "/setup/team",
  MATCH: "/setup/match",
  BET_SETTING: "/setup/bet-setting",
  PACKAGE_SETTING: "/setup/package-setting",
  RUNNING_COEFICIENT: "/setup/running-coeficient",

  ODDS_BASE: "/odd",
  ODDS: "/odd/odds",
  TG_OE: "/odd/tg-oe",
  CANCEL_BET_MANAGER: "/odd/cancel-bet-manager/:pubid",
  CORRECT_SCORE: "/odd/correct-score",

  BETS: "/bets",
  RUN_MANAGER: "/bets/run-manager",
  LEAGUE_RUN_MANAGER: "/bets/league-run-manager",
  WAITING_TICKET: "/bets/waiting-ticket",
  CANCEL_TICKET: "/bets/cancel-single",
  PARLAY_REFUND: "/bets/parlay-refund",
  RECENT_TICKET: "/bets/recent-ticket",
  BIG_DEPOSIT: "/bets/big-deposit",
  BIG_WINNER: "/bets/big-winner",
  MEMBER_BETS: "/bets/member-bets",
  ALL_BETS: "/bets/all-bets",
  ALL_BETS_NATIVE: "/bets/bets-native",
  ALL_PARLAYS: "/bets/all-parlays",
  OUTSTANDING: "/bets/outstanding",
  PARLAY_DETAIL: "/bets/parlay-detail/:pubid",
  LAST_BET_DEPOSIT: "/bets/last-bet-deposit",

  RESULT: "/result",
  UPDATE_RESULT: "/result/update",
  ROLLBACK: "/result/rollback",
  ROLLBACK_REQUEST_LOGS: "/result/request-logs",
  NEGATIVE_MEMBER: "/result/negative-member",

  ANNOUNCEMENT_BASE: "/announcement",
  ANNOUNCEMENT_MESSAGES: "/announcement/messages",
  ANNOUNCEMENT_CONTENT: "/announcement/content",

  SPIDER: "/spider",
  SPIDER_TASK: "/spider/task",
  SBOTOP_PARAM: "/spider/sbotop_param",
  SPIDER_SPEC: "/spider/spider_spec",
  IP_WHITELIST: "/spider/ip_whihelist",
  SPIDER_SPECIAL_LEAGUE: "/spider/special_league",

  BLACKLIST: "/blacklist",
  BLACKLIST_AGENT: "/blacklist/agent",
  BLACKLIST_PLAYER: "/blacklist/player",

  ADMINBASE: "/adminbase",
  ADMIN: "/adminbase/admin",
  MAINTENANCE: "/adminbase/maintenance",
  SETTINGS: "/adminbase/settings",
  OPERATION_LOG: "/adminbase/operation-log",

  CHANGE_PASSWORD: "/change-password",

  CLOSE_MARKET: "/close-market",

  // AGENT_USER: "/agent/user",
  // AGENT_USER_VENDOR: "/agent/user/vendor",
  // AGENT_USER_SENIOR: "/agent/user/senior",
  // AGENT_USER_MASTER: "/agent/user/master",
  // AGENT_USER_AGENT: "/agent/user/agent",
  // AGENT_USER_MEMBER: "/agent/user/member",

  // AGENT_REPORT: "/agent/report/",
  // AGENT_REPORT_GENERAL: "/agent/report/general",
  // AGENT_REPORT_GAMECATEGORY: "/agent/report/gameCategory",

  // AGENT_BAL: "/agent/bal/",
  // AGENT_WINLOSE_REPORT: "/agent/bal/winlose",
};
